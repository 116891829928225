// src/components/ui/SelectInput.js
import React from 'react';

function Select({ label, name, value, onChange, options, multiple = false, placeholder = 'Select an option' }) {
    return (
        <div className="form-group">
            {label && <label htmlFor={name}>{label}</label>}
            <select
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                multiple={multiple}
                className="form-control"
            >
                {!multiple && <option value="">{placeholder}</option>}
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default Select;
