import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/blocks/Header';
import Footer from './components/blocks/Footer';
import Sidebar from './components/blocks/Sidebar';
import MainPage from './pages/MainPage';
import SettingsPage from './pages/SettingsPage';
import AlertsPage from './pages/AlertsPage';
import LoginPage from './pages/LoginPage'; 
import PrivateRoute from './components/PrivateRoute'; 
import { AuthProvider, useAuthContext } from './contexts/AuthContext';

function App() {
    const { user, loading } = useAuthContext();
    const location = useLocation();

    if (loading) {
        return <div>Loading...</div>;
    }

    const currentPathClass = location.pathname.replace('/', '') || 'home';
    
    return (
        <div className={`App ${currentPathClass}`}>
            {user && <Header />}
            {user && <Sidebar />}
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/" element={<PrivateRoute element={<MainPage />} />} />
                <Route path="/settings" element={<PrivateRoute element={<SettingsPage />} />} />
                <Route path="/alerts" element={<PrivateRoute element={<AlertsPage />} />} />
                <Route path="*" element={<Navigate to={user ? '/' : '/login'} />} />
            </Routes>
            {user && <Footer />}
        </div>
    );
}

function AppWrapper() {
    return (
        <Router>
            <AuthProvider>
                <App />
            </AuthProvider>
        </Router>
    );
}

export default AppWrapper;