// src/services/authService.js

import axiosConfig from '../axiosConfig';

// Функция для выполнения логина
export const login = async (email, password) => {
    try {
        const response = await axiosConfig.post('/api/login', { email, password });
        const { accessToken, accessTokenExpiryDate } = response.data;

        if (accessTokenExpiryDate <= Date.now()) {
            throw new Error('Invalid expiresIn value. It should be a valid future Unix timestamp.');
        }
    
        // Сохраняем токен и время истечения токена в localStorage
        localStorage.setItem('authToken', accessToken);
        localStorage.setItem('tokenExpiresIn', accessTokenExpiryDate); // expiresIn в секундах
        
        return response.data;
    } catch (error) {
        console.error('Login failed:', error);
        throw error;
    }
};

// Пример проверки токена на истечение
export const isTokenValid = () => {
    const tokenExpiresIn = localStorage.getItem('tokenExpiresIn');

    if (!tokenExpiresIn) {
        return false;
    }

    // Проверяем, не истёк ли токен
    const now = Date.now();
    return now < parseInt(tokenExpiresIn, 10);
};

export const refreshAccessToken = async (axiosInstance) => {
  try {
      const response = await axiosInstance.post('/api/refresh-token');
      const { accessToken, expiresIn } = response.data;
  
      if (!expiresIn || isNaN(parseInt(expiresIn, 10))) {
          throw new Error('Invalid expiresIn valuse');
      }
  
      return { accessToken, expiresIn: expiresIn.toString() };
  } catch (error) {
      if (error.response) {
        throw new Error(error.response.data.message);
      }
      throw error;
  }
};
  