import React from 'react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useTranslation } from 'react-i18next';

// Компонент для отображения графика давления
const PressureChart = ({ inputValue = [], outputValue = [] }) => {
    const { t } = useTranslation();

    // Преобразуем данные в формат, подходящий для AreaChart
    const data = inputValue.map((point, index) => ({
        x: point.x,
        input: point.y,
        output: outputValue[index] ? outputValue[index].y : 0
    }));

    // Создаем массив ticks для оси Y
    const yTicks = Array.from({ length: 9 }, (_, index) => index);

    // Форматирование времени для оси X
    const formatTime = (tick) => {
        const date = new Date(tick);
        return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    };

    // Настройка форматирования времени в подсказке
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const date = new Date(label);
            const formattedTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
            return (
                <div className="chartTooltip">
                    <span className="label">{`${formattedTime}`}</span>
                    <span>{`OUT: ${payload[0].value.toFixed(1)}`}</span>
                    <span>{`IN: ${payload[1].value.toFixed(1)}`}</span>
                </div>
            );
        }
        return null;
    };

    return (
        <div className="graph">
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    data={data}
                    margin={{ top: 0, right: 15, left: -30, bottom: 0 }}
                >
                    <CartesianGrid vertical={false} stroke="rgba(100,100,100,0.3)" strokeWidth={1} />
                    <XAxis
                        dataKey="x"
                        tick={yTicks}
                        tickFormatter={formatTime}
                    />
                    <YAxis
                        domain={[0, 8]}
                        ticks={yTicks}
                        tickFormatter={(value) => value}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend wrapperStyle={{ paddingTop: "0px", paddingBottom: "0px" }} />
                    <Area
                        type="monotone" // Плавная интерполяция
                        dataKey="input"
                        stroke="#079C69"
                        fillOpacity={0.3}
                        fill="#079C69"
                        name={t('charts.in_pressure')}
                        animationDuration={200}
                    />
                    <Area
                        type="monotone" // Плавная интерполяция
                        dataKey="output"
                        stroke="#008FFB"
                        fillOpacity={0.3}
                        fill="#008FFB"
                        name={t('charts.out_pressure')}
                        animationDuration={200}
                    />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

export default PressureChart;
