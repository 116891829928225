import '../assets/css/settings.css';
import React, { useState, useEffect } from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Select from '../components/ui/Select';
import { useTranslation } from 'react-i18next';
// графика
import icon_gauge from '../assets/img/gauge.svg';
import icon_pump from '../assets/img/pump.svg';
import icon_filter from '../assets/img/filter.svg';

function SettingsPage() {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({ scenario: '' });
    const [selectedScheme, setSelectedScheme] = useState([]);
    const [schemeDescription, setSchemeDescription] = useState('');
    const [sensorCount, setSensorCount] = useState(2);

    const monitoring1sensors = t('settings.psensor_settings.monitoring1sensors', { returnObjects: true });
    const monitoring2sensors = t('settings.psensor_settings.monitoring2sensors', { returnObjects: true });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        const selectedOption = (sensorCount === 1 ? monitoring1sensors : monitoring2sensors).find(option => option.value === value);
        if (selectedOption) {
            setSelectedScheme(selectedOption.scheme);
            setSchemeDescription(selectedOption.desc || '');
        } else {
            setSelectedScheme([]);
            setSchemeDescription('');
        }
    };

    const handleSensorCountChange = (count) => {
        setSensorCount(count);
        setFormData({ ...formData, scenario: '' });
        setSelectedScheme([]);
        setSchemeDescription('');
    };

    useEffect(() => {
        // Обновляем описание схемы при изменении языка или выбранного сценария
        const selectedOption = (sensorCount === 1 ? monitoring1sensors : monitoring2sensors).find(option => option.value === formData.scenario);
        if (selectedOption) {
            setSchemeDescription(selectedOption.desc || '');
        } else {
            setSchemeDescription('');
        }
    }, [t, formData.scenario, monitoring1sensors, monitoring2sensors]);

    const mapping = {
        'gauge1': 'P_IN',
        'gauge2': 'P_OUT',
        'temp': 't_home',
        'humi': 'rh'
    };

    const blocks = (
        <div className="form_box">
            <h2>{t('settings.psensor_settings.header')}</h2>
            <div className="set">
                <div className="form">
                    <p className="form_tip">
                        {t('settings.psensor_settings.top_text').split('[buttons]')[0]}
                        <u 
                            className={sensorCount === 1 ? 'active' : ''} 
                            onClick={() => handleSensorCountChange(1)}
                        >1</u>  
                        <u 
                            className={sensorCount === 2 ? 'active' : ''} 
                            onClick={() => handleSensorCountChange(2)}
                        >2</u>
                        {t('settings.psensor_settings.top_text').split('[buttons]')[1]}
                    </p>
                    <Select
                        label={t('settings.psensor_settings.iwanttomonitor')}
                        name="scenario"
                        value={formData.scenario}
                        onChange={handleChange}
                        options={sensorCount === 1 ? monitoring1sensors : monitoring2sensors}
                        placeholder={t('settings.psensor_settings.scenario_placeholder')}
                    />
                    {selectedScheme && (
                        <div className="scheme">
                            {selectedScheme.map((item, index) => (
                                item === 'arrow' ? (
                                    <div key={index} className="arrow" />
                                ) : (
                                    <div key={index}>
                                        <img
                                            className={Object.keys(item)[0]}
                                            src={
                                                Object.keys(item)[0] === 'gauge1' || Object.keys(item)[0] === 'gauge2' 
                                                    ? icon_gauge 
                                                    : Object.keys(item)[0] === 'gauge' 
                                                        ? icon_pump 
                                                        : icon_filter
                                            }
                                        />
                                        <span><strong>{mapping[Object.keys(item)[0]]}</strong><br /> {Object.values(item)[0][0]}</span>
                                    </div>
                                )
                            ))}
                        </div>
                    )}
                    <div className="form_tip" id="scheme_desc">
                        {schemeDescription}
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <main>
            <div className="flex1">
                <ResponsiveMasonry
                    columnsCountBreakPoints={{ 350: 1, 600: 1, 900: 2, 1200: 4 }}
                >
                    <Masonry>
                        {blocks}
                    </Masonry>
                </ResponsiveMasonry>
            </div>
        </main>
    );
}

export default SettingsPage;
