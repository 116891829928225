// src/pages/MainPage.js
import React from 'react';

function AlertsPage() {
    return (
        <div>
            <h2>Alerts</h2>
        </div>
    );
}

export default AlertsPage;
