import React, { useEffect, useState, useRef } from 'react';
import PressureChart from '../components/charts/PressureChart';
import { useAppContext } from '../contexts/AppContext';
import { useTranslation } from 'react-i18next';

function MainPage() {
    const { t } = useTranslation();
    const { inputValue, outputValue } = useAppContext();

    const [flashInput, setFlashInput] = useState(false);
    const [flashOutput, setFlashOutput] = useState(false);

    const lastInputValueRef = useRef(null);
    const lastOutputValueRef = useRef(null);

    const lastInputChangeTimeRef = useRef(Date.now());
    const lastOutputChangeTimeRef = useRef(Date.now());

    useEffect(() => {
        const newInputValue = inputValue.slice(-1)[0]?.y.toFixed(1);
        const lastInputValue = lastInputValueRef.current?.y.toFixed(1);

        const newOutputValue = outputValue.slice(-1)[0]?.y.toFixed(1);
        const lastOutputValue = lastOutputValueRef.current?.y.toFixed(1);

        const currentTime = Date.now();

        if (newInputValue !== lastInputValue) {
            if (currentTime - lastInputChangeTimeRef.current >= 3000) {
                setFlashInput(true);
                setTimeout(() => setFlashInput(false), 500); // Flash duration
            }
            lastInputChangeTimeRef.current = currentTime;
        }

        if (newOutputValue !== lastOutputValue) {
            if (currentTime - lastOutputChangeTimeRef.current >= 3000) {
                setFlashOutput(true);
                setTimeout(() => setFlashOutput(false), 500); // Flash duration
            }
            lastOutputChangeTimeRef.current = currentTime;
        }

        lastInputValueRef.current = inputValue.slice(-1)[0];
        lastOutputValueRef.current = outputValue.slice(-1)[0];
    }, [inputValue, outputValue]);

    return (
        <main>
            <PressureChart inputValue={inputValue} outputValue={outputValue} />
            <div className="data ns">
                <div>
                    <p>IN</p>
                    <span className={flashInput ? 'flash' : ''}>{inputValue.slice(-1)[0]?.y.toFixed(1)}</span><i>Bar</i>
                </div>
                <div>
                    <p>OUT</p>
                    <span className={flashOutput ? 'flash' : ''}>{outputValue.slice(-1)[0]?.y.toFixed(1)}</span><i>Bar</i>
                </div>
            </div>
        </main>
    );
}

export default MainPage;