import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';

const PrivateRoute = ({ element }) => {
    const { user } = useAuthContext();

    return user ? element : <Navigate to="/login" />;
};

export default PrivateRoute;
