// src/components/Header.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import icon_home from '../../assets/icons/home.svg';
import icon_settings from '../../assets/icons/settings.svg';
import icon_alerts from '../../assets/icons/alerts.svg';
import icon_wifi from '../../assets/icons/wifi.svg';
import { useAppContext } from '../../contexts/AppContext';  // Импортируем хук для контекста
import { useTranslation } from 'react-i18next';


function Header() {
    const { t } = useTranslation();

    const links = [
        { to: "/", label: t('menu.home'), icon: icon_home, tooltip: t('menu.home') },
        { to: "/settings", label: t('menu.settings'), icon: icon_settings, tooltip: t('menu.settings') },
        { to: "/alerts", label: t('menu.alerts'), icon: icon_alerts, tooltip: t('menu.alerts') },
        { to: "/connection", label: t('menu.connection'), icon: icon_wifi, tooltip: t('menu.connection') },
    ];

    // Используем хук для получения данных из контекста
    const { temperature, humidity } = useAppContext();

    const [integerPart, decimalPart] = temperature !== null ? temperature.toString().split('.') : ['--', null];

    return (
        <header className="App-header">
            <ul>
                {links.map(({ to, label, icon, tooltip }) => (
                    <li key={to}>
                        <NavLink
                            to={to}
                            className={({ isActive }) => (isActive ? "active" : "")}
                            data-tooltip={tooltip}
                        >
                            <img src={icon} alt={`${label} icon`} />
                            <span>{label}</span>
                        </NavLink>
                    </li>
                ))}
            </ul>
            <div>
                {/* Отображаем данные температуры и влажности
                <h1>
                    <span className="white">
                        {temperature !== null ? (
                            <>
                                {integerPart}
                                {decimalPart && <i>.{decimalPart} °C</i>}
                            </>
                        ) : '...'}
                    </span>
                </h1>
                <span className="white h1_slogan">{humidity !== null ? `${humidity}%` : ''}</span>
                */}
            </div>
        </header>
    );
}

export default Header;