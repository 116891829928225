import React, { useState } from 'react';
import '../assets/css/login.css';
import telegram from '../assets/soc/telegram.svg';
import { login } from '../services/authService';


function LoginPage() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = ({ target: { name, value } }) => {
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleLogin = async (event) => {
    
    event.preventDefault();
    setLoading(true);
    setError('');

    try {
      
      const success = await login(formData.email, formData.password);
      if (success) {
        window.location.href = '/';
      } else {
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Произошла ошибка');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <h1><span>NI</span><i>HOME</i></h1>
      <form onSubmit={handleLogin}>
        {['email', 'password'].map((field) => (
          <label key={field}>
            <input 
              type={field === 'password' ? 'password' : 'text'} 
              name={field} 
              placeholder={field === 'password' ? 'Пароль' : 'Email'} 
              value={formData[field]}
              onChange={handleChange}
            />
          </label>
        ))}
        <button type="submit" disabled={loading}>
          {loading ? 'Вход...' : 'Войти'}
        </button>
        {error && <p className="error">{error}</p>}
        <p className="desc">Логин можно получить<br /> при <a href="#">наличии устройства</a></p>
        <div title="Обсуждения в чате" className="support">
          <img src={telegram} alt="Telegram" /> inhome_support
        </div>
      </form>
    </div>
  );
}

export default LoginPage;
