import React, { createContext, useState, useContext, useEffect } from 'react';
import { login as authLogin, isTokenValid } from '../services/authService';
import axiosConfig from '../axiosConfig';

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    // Функция для получения информации о пользователе
    const fetchUserInfo = async () => {
        try {
            const response = await axiosConfig.get('/api/user-info');
            setUser(response.data);
        } catch (error) {
            console.error('Failed to fetch user info', error);
            setUser(null); // Убедитесь, что пользователь равен null, если получение данных не удалось
        } finally {
            setLoading(false); // Завершение загрузки
        }
    };

    useEffect(() => {
        const checkAuth = async () => {
            if (isTokenValid()) {
                // Устанавливаем токен в заголовки
                //axiosConfig.defaults.headers.Authorization = `Bearer ${token}`;
                await fetchUserInfo(); // Попытка получить информацию о пользователе, если токен существует
            } else {
                setLoading(false); // Если токена нет, завершите загрузку
            }
        };

        checkAuth();
    }, []);

    const login = async (email, password) => {
        try {
            await authLogin(email, password);
            const token = localStorage.getItem('authToken');
            if (token) {
                // Устанавливаем токен в заголовки
                axiosConfig.defaults.headers.Authorization = `Bearer ${token}`;
                await fetchUserInfo(); // Получаем данные пользователя после успешного логина
            }
        } catch (error) {
            console.error('Login error:', error);
            throw error;
        }
    };

    const logout = () => {
        setUser(null);
        localStorage.removeItem('authToken');
        delete axiosConfig.defaults.headers.Authorization; // Удаляем заголовок авторизации
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuthContext() {
    return useContext(AuthContext);
}

export default AuthContext;
