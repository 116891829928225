// src/components/Footer.js
import React, { useContext, useState } from 'react';
import AppContext from '../../contexts/AppContext';
import { useTranslation } from 'react-i18next';

function Footer() {
    const { connected, disconnectReason } = useContext(AppContext);
    const { t, i18n } = useTranslation();
    const [theme, setTheme] = useState('light');

    // Функция для смены языка
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    // Функция для смены темы
    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        document.documentElement.className = newTheme === 'light' ? 'wte' : 'dte';
    };

    return (
        <footer>
            {connected ? (
                <>
                    <span>🟢 Онлайн</span>
                    <div>
                        <nav>
                            <a
                                href="#"
                                onClick={(e) => { e.preventDefault(); changeLanguage('en'); }}
                                className={i18n.language === 'en' ? 'active' : ''}
                            >
                                English
                            </a>
                            <a
                                href="#"
                                onClick={(e) => { e.preventDefault(); changeLanguage('ru'); }}
                                className={i18n.language === 'ru' ? 'active' : ''}
                            >
                                Русский
                            </a>
                        </nav>
                    </div>
                    <div className="right">
                        <span>⏳ 2d 3m</span>
                    </div>
                </>
            ) : (
                <>
                    <span>🔴 {t('Disconnected...')} {disconnectReason}</span> <span>❓</span>
                </>
            )}
            <button onClick={toggleTheme}>
                {theme === 'light' ? '🌞' : '🌜'}
            </button>
        </footer>
    );
}

export default Footer;