import React, { useState, useEffect, StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AppProvider } from './contexts/AppContext';
import { AuthProvider } from './contexts/AuthContext';
import { isTokenValid } from './services/authService';
import instance, { refreshInstance, refreshTokenIfNeeded as refreshTokenIfNeededFromConfig } from './axiosConfig';
import './i18n';

const AppWrapper = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const initializeApp = async () => {
      if (!isTokenValid()) {
        try {
          const token = localStorage.getItem('authToken');
          let config = { headers: { Authorization: `Bearer ${token}` } };
          config = await refreshTokenIfNeededFromConfig(refreshInstance, config);

          if (config.headers.Authorization) {
            localStorage.setItem('authToken', config.headers.Authorization.split(' ')[1]);
          }
        } catch (error) {
          // Очистка localStorage и перенаправление на страницу входа при ошибке обновления токена
          localStorage.removeItem('authToken');
          localStorage.removeItem('tokenExpiresIn');
          window.location.href = '/login';
          return;
        }
      }

      setIsLoaded(true);
    };

    initializeApp();
  }, []);

  if (!isLoaded) {
    return <div>Loading...</div>; // Можно заменить на компонент загрузки
  }

  return (
    <AuthProvider>
      <AppProvider>
        <App />
      </AppProvider>
    </AuthProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<AppWrapper />);

serviceWorkerRegistration.unregister();