// src/contexts/AppContext.js
import React, { createContext, useState, useEffect, useRef, useContext } from 'react';

const AppContext = createContext();

export function AppProvider({ children }) {
    const [inputValue, setInputValue] = useState([{ x: new Date(), y: 0.0 }]);
    const [outputValue, setOutputValue] = useState([{ x: new Date(), y: 0.0 }]);
    const [temperature, setTemperature] = useState(null);
    const [humidity, setHumidity] = useState(null);
    const [connected, setConnected] = useState(false);
    const [disconnectReason, setDisconnectReason] = useState('');
    const wsRef = useRef(null);

    const connectWebSocket = () => {
        // Проверка наличия токена
        const token = localStorage.getItem('authToken');
        if (!token) {
            console.log('Token is not available, skipping WebSocket connection');
            return;
        }

        const ws = new WebSocket('wss://192.168.1.17/ws');

        ws.onopen = () => {
            setConnected(true);
            setDisconnectReason('');
            console.log('Connected to WebSocket server');
        };

        ws.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                const now = new Date();

                // Обновляем состояния в зависимости от данных
                if (data.p_in !== undefined) {
                    setInputValue(prevValues => [...prevValues, { x: now, y: parseFloat(data.p_in) }]);
                }
                if (data.p_out !== undefined) {
                    setOutputValue(prevValues => [...prevValues, { x: now, y: parseFloat(data.p_out) }]);
                }
                if (data.temp !== undefined) {
                    setTemperature(data.temp);
                }
                if (data.rh !== undefined) {
                    setHumidity(data.rh);
                }
            } catch (error) {
                console.error('Ошибка при парсинге JSON:', error);
            }
        };

        ws.onclose = () => {
            setConnected(false);
            console.log('Disconnected from WebSocket server');
            setTimeout(() => {
                connectWebSocket();
            }, 1000);
        };

        ws.onerror = (error) => {
            setConnected(false);
            const errorMessage = error.message
                ? `Error: ${error.message}`
                : error.code
                    ? `Error code: ${error.code}`
                    : 'Connection closed unexpectedly';

            setDisconnectReason(errorMessage);
            console.error('WebSocket error:', error);
            ws.close();
        };

        wsRef.current = ws;
    };

    useEffect(() => {
        connectWebSocket();
        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, []);

    return (
        <AppContext.Provider value={{
            inputValue,
            outputValue,
            temperature,
            humidity,
            connected,
            disconnectReason
        }}>
            {children}
        </AppContext.Provider>
    );
}

// Хук для использования контекста
export function useAppContext() {
    return useContext(AppContext);
}

export default AppContext;
