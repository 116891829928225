import axios from 'axios';
import { refreshAccessToken } from './services/authService';

// Создаем основной экземпляр axios
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

// Создаем отдельный экземпляр axios для обновления токена
const refreshInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

// Переменные для контроля обновления токена
let isRefreshing = false;
let subscribers = [];

// Функция, вызываемая после успешного обновления токена
function onRefreshed(token) {
  subscribers.forEach(callback => callback(token));
  subscribers = [];
}

// Добавление нового подписчика на обновление токена
function addSubscriber(callback) {
  subscribers.push(callback);
}

async function refreshTokenIfNeeded(refreshInstance, config) {
  console.log('Starting refreshTokenIfNeeded');

  const expiresIn = localStorage.getItem('tokenExpiresIn');
  console.log('Token expiresIn:', expiresIn);

  // Проверяем, истек ли токен
  if (expiresIn && new Date().getTime() > parseInt(expiresIn, 10)) {
    console.log('Token has expired');

    if (!isRefreshing) {
      isRefreshing = true;
      console.log('Starting token refresh');

      try {
        const response = await refreshAccessToken(refreshInstance);
        console.log('Token refreshed successfully:', response);

        const { accessToken, expiresIn: newExpiresIn } = response;

        // Преобразуем время истечения в Unix-время (timestamp)
        const newExpiresAt = Date.now() + newExpiresIn * 1000;
        console.log('New token expires at:', newExpiresAt);

        // Сохраняем обновленные данные в localStorage
        localStorage.setItem('authToken', accessToken);
        localStorage.setItem('tokenExpiresIn', newExpiresAt.toString());
        console.log('Updated authToken and tokenExpiresIn in localStorage');

        // Обновляем заголовок Authorization с новым токеном
        config.headers.Authorization = `Bearer ${accessToken}`;
        console.log('Updated Authorization header with new token');

        isRefreshing = false;
        onRefreshed(accessToken);
        console.log('Token refresh complete');
      } catch (error) {
        isRefreshing = false;
        console.error('Error refreshing token:', error);

        if (error.message.includes('Invalid or expired session')) {
          // Если refresh токен не валиден, очищаем хранилище и перенаправляем на страницу входа
          localStorage.removeItem('authToken');
          localStorage.removeItem('tokenExpiresIn');
          if (window.location.pathname !== '/login') {
            window.location.href = '/login';
          }
          console.log('Invalid session. Cleared localStorage and redirected to /login');
        }
        return Promise.reject(error);
      }
    } else {
      // Если обновление токена уже выполняется, подписываемся на завершение
      console.log('Token refresh already in progress. Subscribing to the event');
      return new Promise((resolve) => {
        addSubscriber((token) => {
          config.headers.Authorization = `Bearer ${token}`;
          console.log('Received new token from subscriber:', token);
          resolve(config);
        });
      });
    }
  }

  console.log('Token is still valid');
  return config;
}

// Перехватчик запросов
instance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('authToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config = await refreshTokenIfNeeded(refreshInstance, config);
  return config;
}, (error) => Promise.reject(error));

export { refreshInstance, refreshTokenIfNeeded };
export default instance;